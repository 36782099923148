import { storeType } from '@/common/appCode';
import { RStore } from './db/r-store';

/** アプリケーションで用いるモデル **/
export type StoreModel = RStore;

export class Store {
  constructor(public params: StoreModel) {}

  /** Getter */
  get id(): string {
    return this.params.id;
  }

  get code(): string {
    return this.params.code;
  }

  get type(): storeType {
    return this.params.type;
  }

  get name(): string {
    return this.params.name;
  }

  get shortName(): string {
    return this.params.shortName;
  }

  get address(): string {
    return this.params.address;
  }

  get tel(): string {
    return this.params.tel;
  }

  get sort(): number {
    return this.params.sort;
  }
}
