import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_select, {
      modelValue: _ctx.coupon.id,
      "onUpdate:modelValue": [
        _cache[0] || (_cache[0] = ($event: any) => ((_ctx.coupon.id) = $event)),
        _ctx.updateCouponItem
      ],
      items: _ctx.couponItems,
      "item-title": "title",
      "item-value": "value",
      hint: "クーポンを選択してください",
      density: "compact",
      variant: "outlined",
      clearable: !_ctx.required,
      "persistent-hint": "",
      rules: _ctx.rules.require,
      disabled: _ctx.disabled
    }, null, 8, ["modelValue", "items", "clearable", "rules", "disabled", "onUpdate:modelValue"]),
    _createVNode(_component_v_checkbox, {
      modelValue: _ctx.coupon.useType,
      "onUpdate:modelValue": [
        _cache[1] || (_cache[1] = ($event: any) => ((_ctx.coupon.useType) = $event)),
        _ctx.updateCouponItem
      ],
      "true-value": _ctx.useTypeMultiTime,
      "false-value": _ctx.useTypeOneTime,
      disabled: !_ctx.existEndDate || _ctx.disabled,
      label: "利用回数の制限なし",
      "hide-details": "auto"
    }, null, 8, ["modelValue", "true-value", "false-value", "disabled", "onUpdate:modelValue"])
  ], 64))
}