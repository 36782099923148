import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5dae6934"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "text-center" }
const _hoisted_2 = { class: "mt-2 text-white text-center font-weight-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_v_overlay = _resolveComponent("v-overlay")!

  return (_openBlock(), _createBlock(_component_v_overlay, {
    "model-value": _ctx.display,
    class: "align-center justify-center overlay-index",
    persistent: ""
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_v_progress_circular, {
          color: "white",
          indeterminate: "",
          size: "64"
        })
      ]),
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.message ? _ctx.message : ''), 1)
    ]),
    _: 1
  }, 8, ["model-value"]))
}