import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_alert = _resolveComponent("v-alert")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_snackbar = _resolveComponent("v-snackbar")!

  return (_openBlock(), _createBlock(_component_v_snackbar, {
    modelValue: _ctx.display,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.display) = $event)),
    color: "error",
    timeout: 10000
  }, {
    actions: _withCtx(() => [
      _createVNode(_component_v_btn, _mergeProps({
        icon: "",
        theme: "dark"
      }, _ctx.$attrs, {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.display = false))
      }), {
        default: _withCtx(() => [
          _createVNode(_component_v_icon, null, {
            default: _withCtx(() => [
              _createTextVNode("close")
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 16)
    ]),
    default: _withCtx(() => [
      _createVNode(_component_v_alert, { type: "error" }, {
        default: _withCtx(() => [
          _createElementVNode("div", null, _toDisplayString(_ctx.message), 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue"]))
}