import { CommonSettings } from '@/common/common-settings';
import { Order, OrderData, RTables } from '@/store/models/db/r-tables';
import { orderType, paymentStatus, salesType, taxRoundingType, taxType } from '@/common/appCode';
import * as Utils from '@/common/utils';
import { Payment } from './db/common-models';
import { EnvConfig } from '@/common/env-config';
import { UserInfo } from './db/r-guests';
import { UserCancelation } from './db/r-settings';
import { OrderTickets } from './order-tickets';

/** アプリケーションで用いるモデル **/
export type TableModel = RTables;

export class Table {
  constructor(public params: TableModel) {}

  /** Noteの最大登録可能最大文字数 */
  public static MAX_NOTE_LENGTH = 150;

  /** カテゴリ名の登録可能最大文字数 */
  public static MAX_REQUEST_LENGTH = 20;

  /**
   * 注文の種類を取得
   *
   * @param type
   */
  public getOrderKind(type: string): {
    order: string;
    lineOrder: string;
    cancel: string;
    change: string;
  } {
    return CommonSettings.ORDER_TYPE[type];
  }

  public get id(): string {
    return this.params.id;
  }

  public get tableNo(): string {
    return this.params.tableNo;
  }

  public get orderedItemsArray(): OrderData[] {
    return this.order.flatMap((val) => val.orderData);
  }

  get order(): Order[] {
    return this.params.order ?? [];
  }

  get orderTickets(): OrderTickets[] {
    return this.params.orderTickets
      ? Object.keys(this.params.orderTickets).map((key) => {
          const val = this.params.orderTickets[key];
          return new OrderTickets(
            val,
            this.id,
            this.asap,
            this.receivingAtDate,
            this.params.isReceivingTimeFree ?? false,
            this.params.trainingMode ?? false,
            { key: val.ticketId }
          );
        })
      : [];
  }

  get servedOrderTickets(): OrderTickets[] {
    return this.orderTickets.filter((val) => !val.params.served);
  }

  public get salesType(): salesType {
    return this.params.salesType;
  }

  public get salesTypeValue(): string {
    return this.params.salesTypeValue;
  }

  /**
   * 合計点数
   */
  public get numberOfitems(): number {
    return this.orderedItemsArray.reduce((res, val) => res + val.count, 0);
  }

  public get deliveryFeeLimitToFree(): number {
    return this.params.deliveryFeeLimitToFree ?? 0;
  }

  public get lineUserIds(): string[] {
    return this.params.lineUsers.map((val) => val.lineUserID);
  }

  public get isEatin(): boolean {
    return this.params.salesType === salesType.eatin;
  }

  public get isTakeout(): boolean {
    return this.params.salesType === salesType.takeout;
  }

  public get isTakeout2(): boolean {
    return this.params.salesType === salesType.takeout2;
  }

  public get isCurbside(): boolean {
    return this.params.salesType === salesType.curbside;
  }

  public get isDelivery(): boolean {
    return this.params.salesType === salesType.delivery;
  }

  public get isDineIn(): boolean {
    return this.params.salesType === salesType.dineIn;
  }

  public get isPicking(): boolean {
    return [salesType.dineIn, salesType.takeout, salesType.takeout2, salesType.curbside, salesType.delivery].includes(
      this.params.salesType
    );
  }

  get isPreOrder(): boolean {
    return this.params.preOrder ?? false;
  }

  /** 取引内で最も多く来店しているユーザーの来店回数を取得 */
  public get maxVisitCount(): number {
    if (!this.params.lineUsers) {
      return 0;
    }
    return this.params.lineUsers.reduce((res, val) => (res > val.numberOfVisit ? res : val.numberOfVisit), 0);
  }

  public get hasLineUser(): boolean {
    return this.params.lineUsers.length >= 1;
  }

  public get isPaid(): boolean {
    return this.params.paymentStatus === paymentStatus.paid;
  }

  public get isInPayment(): boolean {
    return this.params.paymentStatus === paymentStatus.inPayment;
  }

  public get isBeforePayment(): boolean {
    return !this.isPaid && !this.isInPayment;
  }

  public get asap(): boolean {
    return this.params.asap ?? false;
  }

  public get receivingAtDate(): Date {
    return this.params.receivingAt?.toDate();
  }

  public get receivingTimeFree(): boolean {
    return this.params.isReceivingTimeFree ?? false;
  }

  public get entrytimeDate(): Date {
    return this.params.entrytime?.toDate();
  }

  public get checkInDate(): Date {
    return this.params.checkIn?.toDate() ?? this.entrytimeDate;
  }

  public get checkOutDate(): Date {
    return this.params.checkOut?.toDate() ?? new Date();
  }

  public get paymentTimeDate(): Date | null {
    return this.params.paymentTime?.toDate() ?? null;
  }

  public get orderLastTimeAt(): Date | null {
    return (
      this.params.orderHistory
        .filter((val) => [orderType.lineOrder, orderType.order].includes(val.orderType))
        .sort((a, b) => (a.orderedTime.toDate() > b.orderedTime.toDate() ? -1 : 1))[0]
        ?.orderedTime.toDate() ?? null
    );
  }

  public get displayReceiveAt(): string {
    if (this.asap) {
      return '最短';
    }
    return this.params.isReceivingTimeFree
      ? Utils.dateFormatFromMonth(this.receivingAtDate)
      : this.params.isReceivingHourFree
        ? `${Utils.dateHourFormatFromMonth(this.receivingAtDate)}時台`
        : Utils.dateTimeFormatFromMonth(this.receivingAtDate);
  }

  public get displayReceiveTime(): string {
    return this.asap ? '最短' : Utils.timeFormatByColon(this.receivingAtDate);
  }

  public get displayEntryTime(): string {
    return Utils.dateTimeFormatFromMonth(this.entrytimeDate);
  }

  public get displayCheckInTime(): string {
    return Utils.dateTimeFormatFromMonth(this.checkInDate);
  }

  public get displayCheckOutTime(): string {
    return Utils.dateTimeFormatFromMonth(this.checkOutDate);
  }

  public get displayPaymentTime(): string | null {
    return this.paymentTimeDate ? Utils.dateTimeFormatFromMonth(this.paymentTimeDate) : null;
  }

  public get userInfo(): UserInfo {
    return this.params.userInfo ?? {};
  }

  public get tableNoLable(): string {
    return this.isEatin ? '席番号' : '注文番号';
  }

  public get timeBasedBillingEnable(): boolean {
    return this.orderedItemsArray.some((val) => val.timeBasedBilling?.enable ?? false);
  }

  get attachedTableIds(): string[] {
    return this.params.attachedTableIds ?? [];
  }

  get isAttachedTable(): boolean {
    return this.params.isAttachedTable ?? false;
  }

  get primaryTableId(): string {
    return this.params.primaryTableId ?? '';
  }

  get taxRounding(): taxRoundingType {
    return this.params.taxSetting?.taxRounding ?? taxRoundingType.truncate;
  }

  /** 会計情報を取得 */
  get payment(): Payment {
    return Utils.getPayment({
      orderedItems: this.orderedItemsArray,
      discount: this.params.discount,
      point: this.params.usingPoint ?? 0,
      coupon: this.params.usingCoupon,
      deliveryFee: this.params.fee,
      deliveryFeeLimitToFree: this.deliveryFeeLimitToFree,
      serviceChargeRate: this.params.serviceChargeRate ?? 0,
      taxRounding: this.taxRounding,
      timeFrame: { checkIn: this.checkInDate, checkOut: this.checkOutDate },
    });
  }

  get hasMember(): boolean {
    return this.params.lineUsers.some((val) => val.membershipNo);
  }

  /**
   * テーブルに指定された複数の食べ飲み放題にマッチする食べ飲み放題が設定されているかどうか
   *
   * @param targetIds
   */
  public isCategoryTypeMatched(targetIds: string[]): boolean {
    const categoryTypeIds = this.params.availableCategoryTypes.map((b) => b.typeId);
    return (
      [...categoryTypeIds, ...targetIds].filter((item) => categoryTypeIds.includes(item) && targetIds.includes(item))
        .length > 0
    );
  }

  /**
   * 指定した食べ飲み放題が時間内かどうか
   *
   * @param targetIds
   */
  public isEveryCategoryTypeAvailable(targetIds: string[]): boolean {
    const now = new Date();
    return (
      this.params.availableCategoryTypes
        .filter((val) => targetIds.includes(val.typeId))
        // 終了時間前
        .every(
          (val) => val.timer === 0 || Utils.isDateSameOrBefore(now, Utils.addMinutes(val.startTime.toDate(), val.timer))
        )
    );
  }

  /**
   * 指定した食べ飲み放題のいずれかが時間内かどうか
   *
   * @param targetIds
   */
  public isSomeCategoryTypeAvailable(targetIds: string[]): boolean {
    const now = new Date();
    return (
      this.params.availableCategoryTypes
        .filter((val) => targetIds.includes(val.typeId))
        // 終了時間前
        .some(
          (val) => val.timer === 0 || Utils.isDateSameOrBefore(now, Utils.addMinutes(val.startTime.toDate(), val.timer))
        )
    );
  }

  /**
   * 指定された食べ飲み放題が設定されているかどうか
   */
  public hasCategoryType(categoryType: string): boolean {
    return this.params.availableCategoryTypes.map((b) => b.typeId).includes(categoryType);
  }

  /**
   * ModuleChannelプラン用のQRコード情報取得
   *
   * @param lineBotId
   */
  public qrCodeDataModuleChannel(lineBotId: string): string {
    return encodeURI(
      `https://line.me/R/oaMessage/${lineBotId}/?このまま送信ボタンを押してください&${this.params.tableNo}&${this.params.id}`
    );
  }

  /**
   * ミニアプリ版QRコード情報取得
   *
   * @param contractId
   * @param liffUri
   */
  public qrCodeData(contractId: string, liffUri: string): string {
    return encodeURI(`https://miniapp.line.me/${liffUri}/?contractId=${contractId}&tableId=${this.params.id}`);
  }

  /**
   * ブラウザ版QRコード情報取得
   *
   * @param contractId
   */
  public qrCodeDataBrowser(contractId: string, storeId: string): string {
    return encodeURI(
      `https://${EnvConfig.app.APP_HOST}/userOrder?contractId=${contractId}&storeId=${storeId}&tableId=${this.params.id}`
    );
  }

  /**
   * キャンセル期限を取得
   *
   * @param userCancelation
   * @returns
   */
  getCancelationDeadline(userCancelation: UserCancelation): Date | null {
    if (!userCancelation.enable) {
      return null;
    }

    if (userCancelation.limitType === 'day') {
      return Utils.addDays(
        new Date(`${Utils.dateFormatByHyphen(this.receivingAtDate)}T${userCancelation.time}`),
        -userCancelation.day
      );
    } else if (userCancelation.limitType === 'hour') {
      return Utils.addHours(this.receivingAtDate, -userCancelation.hour);
    } else {
      return null;
    }
  }

  /**
   * キャンセル可能かどうか
   *
   * @param userCancelation
   * @returns
   */
  userCancelable(userCancelation: UserCancelation): boolean {
    if (!userCancelation.enable) {
      return false;
    }

    const cancelDeadlineDate = this.getCancelationDeadline(userCancelation);

    // テイクアウト & 支払い前 & キャンセル期限が過ぎていない場合はキャンセル可能
    return this.isPicking && this.isBeforePayment && cancelDeadlineDate
      ? Utils.isBefore(new Date(), cancelDeadlineDate)
      : false;
  }

  /**
   * オーダーストップの判定
   *
   * @param currentDate
   * @param useTimeLimit
   * @param timeLimit
   * @returns
   */
  isOrderStop(currentDate: Date, useTimeLimit: boolean, timeLimit: number): boolean {
    return (
      this.params.orderStop ??
      (useTimeLimit && Utils.isDateAfter(currentDate, Utils.addMinutes(this.params.entrytime.toDate(), timeLimit)))
    );
  }

  getOrderSwitchedTaxRate(type: salesType): Order[] {
    // 変更後の受取方法の税率に変換（税率自動決定商品向け対応）
    return this.order.map(
      (val): Order => ({
        ...val,
        orderData: val.orderData.map(
          (v): OrderData => ({
            ...v,
            taxType: Utils.isReducedTaxRate({ orderData: v, salesType: type }) ? taxType.reduced : taxType.normal,
            reducedTaxRate: Utils.isReducedTaxRate({ orderData: v, salesType: type }),
          })
        ),
      })
    );
  }
}
