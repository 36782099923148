import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_message_list = _resolveComponent("message-list")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_line_message = _resolveComponent("line-message")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_dialog, {
      modelValue: _ctx.display,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.display) = $event)),
      scrollable: "",
      "max-width": "600px"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card, { title: "メッセージ：過去24時間" }, {
          append: _withCtx(() => [
            _createVNode(_component_v_select, {
              modelValue: _ctx.messageNotify,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.messageNotify) = $event)),
              label: "通知音",
              items: _ctx.soundTypes,
              "item-title": "text",
              "item-value": "value",
              variant: "outlined",
              density: "compact"
            }, null, 8, ["modelValue", "items"])
          ]),
          default: _withCtx(() => [
            _createVNode(_component_v_divider),
            _createVNode(_component_v_card_text, { style: {"height":"70%"} }, {
              default: _withCtx(() => [
                _createVNode(_component_message_list, {
                  messages: _ctx.messages,
                  onOnPushReply: _ctx.onPushReplyBtn
                }, null, 8, ["messages", "onOnPushReply"])
              ]),
              _: 1
            }),
            _createVNode(_component_v_divider),
            _createVNode(_component_v_card_actions, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, { cols: "6" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_btn, {
                      block: "",
                      variant: "outlined",
                      onClick: _ctx.onPushCloseBtn
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("閉じる")
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_col, { cols: "6" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_btn, {
                      block: "",
                      color: "primary",
                      variant: "elevated",
                      onClick: _ctx.onPushLineMessageBtn
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("メッセージ作成")
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_line_message, {
      ref: "lineMessage",
      onError: _ctx.errorPropagation
    }, null, 8, ["onError"])
  ], 64))
}