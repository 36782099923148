import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6d1df04b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "note" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!

  return (_openBlock(), _createBlock(_component_v_tooltip, { location: "top" }, {
    activator: _withCtx(({ props }) => [
      _createVNode(_component_v_icon, _mergeProps(props, {
        color: _ctx.color,
        size: _ctx.size,
        onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
      }), {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.icon), 1)
        ]),
        _: 2
      }, 1040, ["color", "size"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.text), 1)
    ]),
    _: 1
  }))
}