import axios from 'axios';

import * as DTO from '@/firebase/dto';
import { APIError } from '@/common/error';
import { functions } from './index';
import { EnvConfig } from '@/common/env-config';
import { FunctionsError, httpsCallable } from 'firebase/functions';
import * as Utils from '@/common/utils';

const apiNames = {
  transaction: 'transaction',
  lineMessage: 'lineMessage',
  order: 'order',
  changeTableNo: 'changeTableNo',
  getMenu: 'getMenu',
  getTable: 'getTable',
  updateMenu: 'updateMenu',
  customer: 'customer',
  smaregi: 'smaregi',
  getCsv: 'getCsv',
  getReceiptData: 'getReceiptData',
  questionnaireAnswer: 'questionnaireAnswer',
  refund: 'refund',
  userEntry: 'userEntry',
  storeNotification: 'storeNotification',
  cancelSubscription: 'cancelSubscription',
  lineConnect: 'lineConnect',
  stripe: 'stripe',
  connectPos: 'connectPos',
  importMenuFromPos: 'importMenuFromPos',
  anywhereOrderConfirm: 'anywhereOrderConfirm',
  ecOrderConfirm: 'ecOrderConfirm',
  sendServiceMessage: 'sendServiceMessage',
  contract: 'contract',
  getSonyPaymentMasterTelegram: 'getSonyPaymentMasterTelegram',
  waiting: 'waiting',
  square: 'square',
  gmo: 'gmo',
  sendSmsAuth: 'sendSmsAuth',
  guestLog: 'guestLog',
  addGuestLog: 'addGuestLog',
  translate: 'translate',
  textSpeech: 'textSpeech',
  store: 'store',
  emberpointStamping: 'emberpointStamping',
  coupon: 'coupon',
  deliveryFeesByPostalCode: 'deliveryFeesByPostalCode',
  // 以下、通販くん用
  ecTransaction: 'ecTransaction',
  updateStatus: 'updateStatus',
};

/**
 * Cloud Functionsを呼び出す
 *
 * @param apiName
 * @param obj
 * @param parseDate リクエスト、レスポンスの値にDate型を含む場合はtrueにする。API側のapiHandlerにもtrueを指定すること
 * @returns
 */
const apiPost = (apiName: string, obj, parseDate: boolean = false) => {
  return httpsCallable(
    functions,
    apiName
  )(parseDate ? Utils.serializeDates(obj) : obj)
    .then((res) => (parseDate ? Utils.deserializeDates(res.data) : res.data))
    .catch((err: FunctionsError) => {
      throw new APIError(err.code, err.message);
    });
};

/**
 * ローカルAPIを呼び出す
 *
 * @param apiName
 * @param obj
 * @param parseDate リクエスト、レスポンスの値にDate型を含む場合はtrueにする。API側のapiHandlerにもtrueを指定すること
 * @returns
 */
const apiPostLocal = (apiName: string, obj, parseDate: boolean = false) => {
  console.log('API Call', apiName);

  const body = new URLSearchParams();
  body.append('data', JSON.stringify(parseDate ? Utils.serializeDates(obj) : obj));
  return (
    axios
      // .post(`https://chumon-api-cacfec07.localhost.run/${apiName}`, body, {})
      .post(`http://${EnvConfig.app.LOCAL_API_HOST}/${apiName}`, body, {})
      .then((res) => (parseDate ? Utils.deserializeDates(res.data) : res.data))
      .catch((err) => {
        throw new APIError(err.response?.status, err.response?.data);
      })
  );
};

// Date型を扱う関数は第3引数（parseDate）にtrueを指定する
const apiFunctions = (apiPost) => ({
  /** 取引 */
  transaction: async <T, U>(obj: T): Promise<U> => apiPost(apiNames.transaction, obj),
  /** 注文 */
  order: async (obj: DTO.OrderReq) => apiPost(apiNames.order, obj),
  /** 席番号の変更 */
  changeTableNo: async (obj: DTO.ChangeTableNoReq) => apiPost(apiNames.changeTableNo, obj),
  /** テーブルの取得 */
  getTable: async (obj: DTO.GetTableReq): Promise<DTO.GetTableRes> => apiPost(apiNames.getTable, obj),
  /** メニューの取得 */
  getMenu: async (obj: DTO.GetMenuReq): Promise<DTO.GetMenuRes> => apiPost(apiNames.getMenu, obj),
  /** メニューの追加更新削除 */
  updateMenu: async <T, U>(obj: T): Promise<U> => apiPost(apiNames.updateMenu, obj),
  /** 顧客 */
  customer: async <T, U>(obj: T): Promise<U> => apiPost(apiNames.customer, obj, true),
  /** スマレジAPI */
  smaregi: async <T, U>(obj: T): Promise<U> => apiPost(apiNames.smaregi, obj),
  /** CSV出力データの取得 */
  getCsv: async <T, U>(obj: T): Promise<U> => apiPost(apiNames.getCsv, obj),
  /** レシートデータ取得 */
  getReceiptData: async <T, U>(obj: T): Promise<U> => apiPost(apiNames.getReceiptData, obj),
  /** アンケート回答 */
  questionnaireAnswer: async <T, U>(obj: T): Promise<U> => apiPost(apiNames.questionnaireAnswer, obj),
  /** Lineメッセージ */
  lineMessage: async <T, U>(obj: T): Promise<U> => apiPost(apiNames.lineMessage, obj),
  /** LINE Pay返金処理 */
  refund: async (obj: DTO.RefundReq) => apiPost(apiNames.refund, obj),
  /** 入店処理 */
  userEntry: async (obj: DTO.UserEntryReq): Promise<DTO.UserEntryRes> => apiPost(apiNames.userEntry, obj),
  /** 店舗通知 */
  storeNotification: async <T, U>(obj: T): Promise<U> => apiPost(apiNames.storeNotification, obj),
  /** LINE連携・解除 */
  lineConnect: async <T, U>(obj: T): Promise<U> => apiPost(apiNames.lineConnect, obj),
  /** 解約 */
  cancelSubscription: async (obj: DTO.CancelSubscriptionReq): Promise<DTO.CancelSubscriptionRes> =>
    apiPost(apiNames.cancelSubscription, obj),
  /** Stripe連結・決済実行 */
  stripe: async <T, U>(obj: T): Promise<U> => apiPost(apiNames.stripe, obj),
  /** ブレインレジ連携 */
  connectPos: async (obj: DTO.ConnectPosReq): Promise<DTO.ConnectPosRes> => apiPost(apiNames.connectPos, obj),
  /** ブレインレジメニュー取り込み */
  importMenuFromPos: async (obj: DTO.ImportMenuFromPosReq): Promise<DTO.ImportMenuFromPosRes> =>
    apiPost(apiNames.importMenuFromPos, obj),
  /** 店外MO注文確定 */
  anywhereOrderConfirm: async (obj: DTO.AnywhereOrderConfirmReq): Promise<DTO.AnywhereOrderConfirmRes> =>
    apiPost(apiNames.anywhereOrderConfirm, obj),
  /** 通販くん注文確定 */
  ecOrderConfirm: async (obj: DTO.EcOrderConfirmReq): Promise<DTO.EcOrderConfirmRes> =>
    apiPost(apiNames.ecOrderConfirm, obj),
  /** 提供準備OKサービスメッセージ */
  sendServiceMessage: async (obj: DTO.SendServiceMessageReq): Promise<DTO.SendServiceMessageRes> =>
    apiPost(apiNames.sendServiceMessage, obj),
  /** 契約締結 */
  contract: async (obj: DTO.ContractReq): Promise<DTO.ContractRes> => apiPost(apiNames.contract, obj),
  /** SonyPayment(PayPay支払) */
  getSonyPaymentMasterTelegram: async (
    obj: DTO.GetSonyPaymentMasterTelegramReq
  ): Promise<DTO.GetSonyPaymentMasterTelegramRes> => apiPost(apiNames.getSonyPaymentMasterTelegram, obj),
  /** 順番待ちAPI */
  waiting: async <T, U>(obj: T): Promise<U> => apiPost(apiNames.waiting, obj),
  /** Square連結等の関連処理 */
  square: async <T, U>(obj: T): Promise<U> => apiPost(apiNames.square, obj),
  /** GMOペイメント関連処理 */
  gmo: async <T, U>(obj: T): Promise<U> => apiPost(apiNames.gmo, obj),

  /** SMS認証送信 */
  sendSmsAuth: async (obj: DTO.SendSmsAuthReq): Promise<DTO.SendSmsAuthRes> => apiPost(apiNames.sendSmsAuth, obj),

  /** 顧客購入情報 */
  guestLog: async <T, U>(obj: T): Promise<U> => apiPost(apiNames.guestLog, obj),

  /** 店舗情報 */
  store: async <T, U>(obj: T): Promise<U> => apiPost(apiNames.store, obj),

  /** エンバーポイントスタンプ付与 */
  emberpointStamping: async (obj: DTO.EmberpointStampingReq): Promise<DTO.EmberpointStampingRes> =>
    apiPost(apiNames.emberpointStamping, obj),

  /** クーポン */
  coupon: async <T, U>(obj: T): Promise<U> => apiPost(apiNames.coupon, obj),

  /** 郵便番号ごとの配送料設定 */
  deliveryFeesByPostalCode: async <T, U>(obj: T): Promise<U> => apiPost(apiNames.deliveryFeesByPostalCode, obj),

  // 以下、通販くん用
  /** EC系API */
  ecTransaction: async <T, U>(obj: T): Promise<U> => apiPost(apiNames.ecTransaction, obj),
  /** 注文ステータス更新 */
  updateStatus: async (obj: DTO.UpdateStatusReq): Promise<DTO.UpdateStatusRes> => apiPost(apiNames.updateStatus, obj),

  /** テキスト翻訳 */
  translate: async <T, U>(obj: T): Promise<U> => apiPost(apiNames.translate, obj),
  /** テキスト読み上げ */
  textSpeech: async (obj: DTO.TextSpeechReq): Promise<DTO.TextSpeechRes> => apiPost(apiNames.textSpeech, obj),
});

export const api = apiFunctions(EnvConfig.app.VUE_APP_MODE === 'local' ? apiPostLocal : apiPost);
