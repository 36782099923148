import * as Utils from '@/common/utils';
import { salesType } from '@/common/appCode';
import { OrderSubMenu } from './db/common-models';
import { Table } from './table';
import { OrderTicket } from './db/r-tables';

export class OrderTickets {
  key: string;

  constructor(
    public params: OrderTicket,
    public tableIdData: string,
    public asap: boolean,
    public receivingAt: Date,
    public isReceivingTimeFree: boolean,
    public trainingMode: boolean,
    option: {
      key: string;
    }
  ) {
    this.key = option.key;
  }

  get tableId(): string {
    return this.tableIdData;
  }

  get isTrainingMode(): boolean {
    return this.trainingMode;
  }

  get shortName(): string {
    return this.params.shortName ?? this.params.name;
  }

  get receivingAtDate(): Date {
    const receivingAtDate = this.receivingAt;

    return receivingAtDate;
  }

  get orderedTimeDate(): Date {
    return this.params.orderedTime?.toDate();
  }

  get servedTimeDate(): Date | undefined {
    return this.params.servedTime?.toDate();
  }

  get displayReceiveAt(): string {
    if (this.asap) {
      return '最短';
    }

    return this.isReceivingTimeFree
      ? Utils.dateFormatFromMonth(this.receivingAtDate)
      : Utils.dateTimeFormatFromMonth(this.receivingAtDate);
  }

  get displayOrderedTime(): string {
    return Utils.timeFormatByColon(this.orderedTimeDate);
  }

  get isEatin(): boolean {
    return this.params.salesType === salesType.eatin;
  }

  get isTakeout(): boolean {
    return this.params.salesType === salesType.takeout;
  }

  get isTakeout2(): boolean {
    return this.params.salesType === salesType.takeout2;
  }

  get isCurbside(): boolean {
    return this.params.salesType === salesType.curbside;
  }

  get isDelivery(): boolean {
    return this.params.salesType === salesType.delivery;
  }

  get isDineIn(): boolean {
    return this.params.salesType === salesType.dineIn;
  }

  get isPicking(): boolean {
    return (
      this.params.salesType === salesType.dineIn ||
      this.params.salesType === salesType.takeout ||
      this.params.salesType === salesType.takeout2 ||
      this.params.salesType === salesType.curbside ||
      this.params.salesType === salesType.delivery
    );
  }

  get positionIds(): string[] {
    return this.params.positionIds ?? [this.params.kitchenNo ?? ''];
  }

  get subMenu(): OrderSubMenu[] {
    return this.params.subMenu;
  }

  getTable(tableObject: { [key: string]: Table }): Table {
    return tableObject[this.tableId];
  }

  getTableNo(tableObject: { [key: string]: Table }): string {
    return this.getTable(tableObject)?.tableNo ?? '';
  }

  getPickUpPlaces(tableObject: { [key: string]: Table }): string {
    return this.getTable(tableObject)?.params.pickUpPlace ?? '';
  }
}

export interface OrderInKitchenModel {
  kitchenNo: string;
  name: string;
  sort_key: number;
  orders: OrderTickets[];
}

export class OrderInKitchen {
  constructor(public params: OrderInKitchenModel) {}
}
