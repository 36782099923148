import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    modelValue: _ctx.display,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.display) = $event)),
    "max-width": "500px"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, { title: "スタッフ選択" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card_text, { class: "text-center py-1" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_select, {
                modelValue: _ctx.selectedStaffId,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedStaffId) = $event)),
                items: _ctx.selectStaffs,
                "item-title": "text",
                "item-value": "value",
                variant: "outlined",
                density: "compact",
                class: "body"
              }, null, 8, ["modelValue", "items"])
            ]),
            _: 1
          }),
          _createVNode(_component_v_card_text, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, { cols: "6" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_btn, {
                        block: "",
                        variant: "outlined",
                        onClick: _ctx.close
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("閉じる")
                        ]),
                        _: 1
                      }, 8, ["onClick"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, {
                    cols: "6",
                    class: "text-right"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_btn, {
                        block: "",
                        color: "primary",
                        variant: "elevated",
                        onClick: _ctx.changeStaff
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("設定")
                        ]),
                        _: 1
                      }, 8, ["onClick"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue"]))
}