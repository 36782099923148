<template>
  <v-card color="blue-lighten-4" rounded="sm" flat class="py-3 px-1" height="100%">
    <v-row dense>
      <v-col cols="1">
        <v-avatar size="34"><v-img :src="avatarImgUrl"></v-img></v-avatar>
      </v-col>
      <v-col cols="11">
        <v-card-text v-for="(item, index) in messageContents" :key="index" class="py-1">
          <v-card rounded="lg" border variant="flat">
            <v-card-text v-if="item.type === 'text'" class="message pa-2">{{ item.message }}</v-card-text>
            <v-img v-else-if="item.type === 'image' && !item.picture" cover :src="item.originalUrl"></v-img>
            <v-img
              v-else-if="item.type === 'image' && item.picture && item.picture.length === 1"
              cover
              :src="getImageUrl(item.picture[0])"
            ></v-img>
            <v-slide-group v-else-if="item.type === 'image' && item.picture && item.picture.length > 1" show-arrows>
              <v-slide-group-item v-for="(picture, index) in item.picture" :key="index">
                <v-card class="mr-2" width="200">
                  <v-img cover eager :src="getImageUrl(picture)" />
                </v-card>
              </v-slide-group-item>
            </v-slide-group>
            <v-img v-else-if="item.type === 'video'" :src="item.thumbnailUrl"></v-img>
            <v-card-text v-else-if="item.type === 'questionnaire'">アンケート</v-card-text>
            <v-card-text v-else-if="item.type === 'coupon'" class="message pa-2"
              >新しいクーポンが付与されました！会員証アプリでご確認いただけます<br /><br />{{ item.couponName
              }}<br /><br />https://miniapp.line.me/会員証URL</v-card-text
            >
          </v-card>
        </v-card-text>
      </v-col>
      <v-col cols="12" class="text-caption mx-2 py-1">{{
        messageContents.length === 0 ? 'メッセージ未登録' : '※ 実際のLINE画面の表示とは異なります'
      }}</v-col>
    </v-row>
  </v-card>
</template>

<script lang="ts">
import { Prop } from 'vue-property-decorator';
import { Options, Vue } from 'vue-class-component';
import { LineMessageContent, LineMessagePictureContent } from '@/store/models/db/common-models';
import { useMediaStore } from '@/stores/media';

/** Interface **/

@Options({})
export default class MessagePreview extends Vue {
  mediaStore = useMediaStore();

  /** Props **/
  @Prop({ default: [] }) messageContents!: LineMessageContent[];
  @Prop({ default: '' }) avatarImgUrl!: string;

  // 画像のURLを返す
  getImageUrl(item: LineMessagePictureContent): string {
    return item.type === 'input' ? item.url : this.mediaStore.getMediaUrls[item.mediaId]?.url ?? '';
  }
}
</script>

<style scoped>
.message {
  white-space: pre-wrap;
  word-wrap: break-word;
  line-height: 1.2em;
}
</style>
