import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3a3342cb"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_timeline_item = _resolveComponent("v-timeline-item")!
  const _component_v_timeline = _resolveComponent("v-timeline")!

  return (_openBlock(), _createBlock(_component_v_timeline, { density: "compact" }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.timeLineItems, (item, index) => {
        return (_openBlock(), _createBlock(_component_v_timeline_item, {
          key: index,
          "fill-dot": "",
          "dot-color": _ctx.getDotColor(item)
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_card_title, { class: "note" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.message), 1)
                  ]),
                  _: 2
                }, 1024),
                _createVNode(_component_v_card_text, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_row, { justify: "space-between" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_col, { class: "auto" }, {
                          default: _withCtx(() => [
                            (_ctx.isSend(item.type))
                              ? (_openBlock(), _createElementBlock("div", _hoisted_1, "To: " + _toDisplayString(item.tableNo), 1))
                              : (_ctx.isReceive(item.type))
                                ? (_openBlock(), _createElementBlock("div", _hoisted_2, "From: " + _toDisplayString(item.lineUserName) + "（" + _toDisplayString(item.tableNo) + "）", 1))
                                : _createCommentVNode("", true),
                            _createElementVNode("div", null, "Date: " + _toDisplayString(_ctx.formateDate(item.sendTime)), 1)
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_v_col, {
                          cols: "auto",
                          class: "text-center pl-0"
                        }, {
                          default: _withCtx(() => [
                            (_ctx.isReceive(item.type))
                              ? (_openBlock(), _createBlock(_component_v_btn, {
                                  key: 0,
                                  variant: "outlined",
                                  onClick: ($event: any) => (_ctx.onPushReply(item.lineUserId, item.lineUserName))
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode("返信")
                                  ]),
                                  _: 2
                                }, 1032, ["onClick"]))
                              : _createCommentVNode("", true)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1024)
          ]),
          _: 2
        }, 1032, ["dot-color"]))
      }), 128))
    ]),
    _: 1
  }))
}