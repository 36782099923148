import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_list_subheader = _resolveComponent("v-list-subheader")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_file_input = _resolveComponent("v-file-input")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    modelValue: _ctx.display,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.display) = $event)),
    "max-width": "750px"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, { class: "scroll-none" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card_title, { class: "text-h5 font-weight-bold" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.titleType) + "登録 ", 1)
            ]),
            _: 1
          }),
          _createVNode(_component_v_card_text, {
            style: {"height":"100%"},
            class: "py-6"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_form, { ref: "form" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_row, { dense: "" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, {
                        cols: "12",
                        sm: "3"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_list_subheader, { class: "text-black" }, {
                            default: _withCtx(() => [
                              _createTextVNode(" タイトル ")
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_col, {
                        cols: "12",
                        sm: "8"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_text_field, {
                            modelValue: _ctx.title,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.title) = $event)),
                            rules: _ctx.rules.title,
                            variant: "outlined",
                            density: "compact"
                          }, null, 8, ["modelValue", "rules"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_col, {
                        cols: "12",
                        sm: "3"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_list_subheader, { class: "text-black" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.titleType) + "ファイル ", 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_col, {
                        cols: "12",
                        sm: "8"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_file_input, {
                            modelValue: _ctx.file,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.file) = $event)),
                            rules: _ctx.rules.required,
                            accept: _ctx.acceptType,
                            label: "ファイルを選択",
                            variant: "solo-filled",
                            density: "compact"
                          }, null, 8, ["modelValue", "rules", "accept"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 512)
            ]),
            _: 1
          }),
          _createVNode(_component_v_divider),
          _createVNode(_component_v_card_actions, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, {
                    cols: "4",
                    sm: "2",
                    offset: "4",
                    "offset-sm": "8",
                    class: "pt-4"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_btn, {
                        block: "",
                        variant: "outlined",
                        onClick: _ctx.onPushCancelBtn
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("閉じる")
                        ]),
                        _: 1
                      }, 8, ["onClick"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, {
                    cols: "4",
                    sm: "2",
                    class: "pt-4"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_btn, {
                        color: "primary",
                        variant: "elevated",
                        block: "",
                        onClick: _ctx.onPushEnterBtn
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_icon, null, {
                            default: _withCtx(() => [
                              _createTextVNode("done")
                            ]),
                            _: 1
                          }),
                          _createTextVNode("保存")
                        ]),
                        _: 1
                      }, 8, ["onClick"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue"]))
}