import { OtherInfoQuestion, RAccountSetting, TaxSetting } from './db/r-account-setting';
import { accountType, productId, rankUpType, taxRoundingType } from '@/common/appCode';

export type AccountSettingModel = RAccountSetting;

export class AccountSetting {
  /** スタートプランの登録可能最大文字数 */
  public static MAX_START_PLAN_CATEGORY_ITEMS = 1;
  /** スタートプランの最大登録可能数 */
  public static MAX_START_PLAN_MENU_ITEMS = 10;

  public static DEFAULT_TAX_SETTING: TaxSetting = {
    taxRounding: taxRoundingType.truncate,
  };

  public params: AccountSettingModel;

  constructor(params: RAccountSetting) {
    this.params = { ...params };
  }

  get is2ndGenData(): boolean {
    return this.params.is2ndGenData ?? false;
  }

  get isEatinStore(): boolean {
    if (!this.params) {
      return false;
    }
    return (
      this.eatinProductId === productId.eatinChumonKunStandard ||
      this.eatinProductId === productId.eatinChumonKunWaiter ||
      this.eatinProductId === productId.eatinChumonKun
    );
  }

  get isEatinStandard(): boolean {
    return this.eatinProductId === productId.eatinChumonKunStandard || this.isEatinPlus;
  }

  get isTakeoutStore(): boolean {
    if (!this.params) {
      return false;
    }
    return (
      this.pickingProductId === productId.takeoutChumonKunPlus ||
      this.pickingProductId === productId.takeoutChumonKun ||
      this.pickingProductId === productId.takeoutChumonKunLight ||
      this.pickingProductId === productId.takeoutChumonKunModuleChannel
    );
  }

  get isTakeoutLight(): boolean {
    return this.pickingProductId === productId.takeoutChumonKunLight || this.isTakeoutStandard;
  }

  get isTakeoutStandard(): boolean {
    return (
      this.pickingProductId === productId.takeoutChumonKun ||
      this.pickingProductId === productId.takeoutChumonKunModuleChannel ||
      this.isTakeoutPlus
    );
  }

  get isTakeoutPlus(): boolean {
    return this.pickingProductId === productId.takeoutChumonKunPlus;
  }

  get isBothStore(): boolean {
    if (!this.params) {
      return false;
    }
    return this.isEatinStore && this.isTakeoutStore;
  }

  get isEcStore(): boolean {
    if (!this.params.subscription) {
      return false;
    }
    return this.params.subscription.productIdEc === productId.ecKun;
  }

  get isWaitingStore(): boolean {
    if (!this.params?.subscription) {
      return false;
    }
    return (
      this.params.subscription.productIdWaiting === productId.waitingMachiko ||
      this.params.subscription.productIdWaiting === productId.waitingMachikoPro
    );
  }

  get isWaitingProStore(): boolean {
    if (!this.params.subscription) {
      return false;
    }
    return this.params.subscription.productIdWaiting === productId.waitingMachikoPro;
  }

  get isMembershipStore(): boolean {
    if (!this.params.subscription) {
      return false;
    }
    return this.params.subscription.productIdMembership === productId.membership;
  }

  get isEcOnlyStore(): boolean {
    if (!this.params.subscription) {
      return false;
    }
    return (
      this.eatinProductId === productId.none &&
      this.pickingProductId === productId.none &&
      this.params.subscription.productIdEc === productId.ecKun
    );
  }

  get isEatinOnlyStore(): boolean {
    if (!this.params) {
      return false;
    }
    return (
      this.eatinProductId === productId.eatinChumonKunStandard ||
      this.eatinProductId === productId.eatinChumonKunWaiter ||
      this.eatinProductId === productId.eatinChumonKun
    );
  }

  get isTakeoutOnlyStore(): boolean {
    if (!this.params) {
      return false;
    }
    return (
      this.pickingProductId === productId.takeoutChumonKunLight ||
      this.pickingProductId === productId.takeoutChumonKun ||
      this.pickingProductId === productId.takeoutChumonKunPlus ||
      this.pickingProductId === productId.takeoutChumonKunModuleChannel
    );
  }

  get isTakeoutStart(): boolean {
    return this.isTakeoutLight || this.isTakeoutStandard;
  }

  /** Module Channel 契約アカウントかどうか */
  get isModuleChannelAccount(): boolean {
    return this.params?.accountType === accountType.moduleChannel;
  }

  /** Messaging API 契約アカウントかどうか */
  get isMessagingApiAccount(): boolean {
    return !this.params?.accountType || this.params?.accountType === accountType.messagingApi;
  }

  get isEatinWaiter(): boolean {
    return this.eatinProductId === productId.eatinChumonKunWaiter;
  }

  get isEatinPlus(): boolean {
    return this.eatinProductId === productId.eatinChumonKun || this.eatinProductId === productId.eatinChumonKunWaiter;
  }

  get eatinProductId(): productId {
    return this.params?.subscription?.productIdEatin ?? '';
  }

  get pickingProductId(): productId {
    return this.params?.subscription?.productIdPicking ?? '';
  }

  get ecProductId(): productId {
    return this.params?.subscription?.productIdEc ?? '';
  }

  get waitingProductId(): productId {
    return this.params?.subscription?.productIdWaiting ?? '';
  }

  get membershipProductId(): productId {
    return this.params?.subscription?.productIdMembership ?? '';
  }

  /** 支店の店舗数 */
  get passwordLockDisabled(): boolean {
    return this.params?.passwordLockDisabled ?? false;
  }

  /** 受取場所追加オプションの数量 */
  get pickUpPlaceOptionNumber(): number {
    return (this.params.subscription.pickUpPlaceOption ?? 0) + 1;
  }

  /** 支店の店舗数 */
  get contractedStoresNum(): number {
    return this.params?.subscription?.contractedStoresNum ?? 1;
  }

  /** POS連携済みかどうか */
  get isPosConnected(): boolean {
    return this.isBlaynPosConnected || this.isSmaregiConnected;
  }

  /** ブレインレジ連携済みかどうか */
  get isBlaynPosConnected(): boolean {
    return this.params?.blaynConnected ? true : false;
  }

  /** スマレジ連携済みかどうか */
  get isSmaregiConnected(): boolean {
    return this.params?.smaregi ? true : false;
  }

  /** ウェイター連携済みかどうか */
  get isWaiterConnected(): boolean {
    return this.params?.smaregi?.waiter ? true : false;
  }

  /** 商品更新時にスマレジ商品を更新するかどうか */
  get isMenuUpdateEnabled(): boolean {
    return this.isSmaregiConnected && !this.isWaiterConnected && this.menuUpdateEnabled;
  }

  get isDisableStockAutomation(): boolean {
    return this.isWaiterConnected || Boolean(this.params.smaregi?.syncStock);
  }

  /** スマレジ会員証アプリと連携済みかどうか */
  get isSmaregiMembershipConnected(): boolean {
    return this.params?.smaregiMembership ? true : false;
  }

  /** Stripe連携済みかどうか */
  get isStripeConnected(): boolean {
    return this.params.stripeConnect ? true : false;
  }

  /** Stripeで支払いが可能か */
  get isStripeChargeEnabled(): boolean {
    return this.params.stripeConnect?.chargesEnabled ? true : false;
  }

  /** ソニーペイメント設定済みかどうか */
  get isSonyPaymentSetUp(): boolean {
    return this.params?.sonyPayment ? true : false;
  }

  /** GMOペイメント設定済みかどうか */
  get isGmoPaymentSetUp(): boolean {
    return this.params?.gmoPayment ? true : false;
  }

  /** Square設定済みかどうか */
  get isSquarePaymentSetUp(): boolean {
    return this.params?.squarePayment ? true : false;
  }

  /** オンライン決済登録済みかどうか */
  get isConnectedOnlinePayment(): boolean {
    return this.isSonyPaymentSetUp || this.isGmoPaymentSetUp || this.isStripeConnected || this.isSquarePaymentSetUp;
  }

  /** board設定済みかどうか */
  get isBoardSetUp(): boolean {
    return Boolean(this.params?.board);
  }

  /** 会員証関連(各質問の使用状況) */
  /** ランクを使用するかどうか */
  get isUseRank(): boolean {
    // ランクが無効の場合はfalse（使用しない）
    return !this.params.membershipInfo?.rankDisabled ?? true;
  }

  get isUseLoginPoint(): boolean {
    return this.loginPoint > 0;
  }
  get isAllowMemberCancellation(): boolean {
    return this.params.membershipInfo?.allowMemberCancellation ?? false;
  }
  get isUseNextBooking(): boolean {
    return this.params.membershipInfo?.useNextBooking ?? false;
  }
  get isUseNameQuestion(): boolean {
    return this.params.membershipInfo?.useNameQuestion ?? true;
  }
  get isUseTelQuestion(): boolean {
    return this.params.membershipInfo?.useTelQuestion ?? false;
  }
  get isUseSexQuestion(): boolean {
    return this.params.membershipInfo?.useSexQuestion ?? false;
  }
  get isUseBirthdayQuestion(): boolean {
    return this.params.membershipInfo?.useBirthdayQuestion ?? false;
  }
  get isUsePostCodeQuestion(): boolean {
    return this.params.membershipInfo?.usePostCodeQuestion ?? false;
  }
  get isUseAddressQuestion(): boolean {
    return this.params.membershipInfo?.useAddressQuestion ?? false;
  }
  get isUseEmailQuestion(): boolean {
    return this.params.membershipInfo?.useEmailQuestion ?? false;
  }
  get otherInfoQuestions(): OtherInfoQuestion[] {
    return this.params.membershipInfo?.otherInfoQuestions ?? [];
  }
  get isRankUpTypeAmount(): boolean {
    return this.params.membershipInfo?.rankUpType === rankUpType.amount;
  }
  get isRankUpTypeCount(): boolean {
    return this.params.membershipInfo?.rankUpType === rankUpType.count;
  }
  get isAskingCustomerInfo(): boolean {
    return (
      this.isUseNameQuestion ||
      this.isUseTelQuestion ||
      this.isUseSexQuestion ||
      this.isUseBirthdayQuestion ||
      this.isUsePostCodeQuestion ||
      this.isUseAddressQuestion ||
      this.isUseEmailQuestion ||
      this.otherInfoQuestions.length > 0
    );
  }

  /** ポイント機能を使うかどうか */
  get usePointFeature(): boolean {
    return this.params.membershipInfo?.usePointFeature ?? false;
  }

  get loginPoint(): number {
    return this.params.membershipInfo?.loginPoint ?? 0;
  }

  get isUseStampCard(): boolean {
    return Boolean(this.params.membershipInfo?.useStampCard);
  }

  get taxSetting(): TaxSetting {
    // taxSettingが未設定の店舗も存在するため、undefinedの場合は初期値を設定
    return (this.params.taxSetting as TaxSetting | undefined) ?? AccountSetting.DEFAULT_TAX_SETTING;
  }

  get taxRounding(): taxRoundingType {
    return this.params.taxSetting?.taxRounding ?? taxRoundingType.truncate;
  }

  /** menuUpdateEnabled */
  private get menuUpdateEnabled(): boolean {
    return this.params?.smaregi?.menuUpdateEnabled ?? true;
  }
}
