import { defineStore } from 'pinia';
import { Ref, computed, ref } from 'vue';
import { Message } from '@/store/models/message';
import { api } from '@/firebase/api';
import * as Utils from '@/common/utils';
import {
  GetMessageDataRes,
  SendMessageItem,
  ReceiveMessageItem,
  GetMessageDataReq,
} from '@/firebase/dto/get-message-data';
import { StoreError } from '@/common/error';
import { MessageTimeLine } from '@/store/models/common-sys-models';
import { LineMessageContent, LineUser } from '@/store/models/db/common-models';
import { docs } from '@/firebase/dao';
import { Unsubscribe, onSnapshot } from 'firebase/firestore';
import { SendLineMessageReq, SendLineMessageRes } from '@/firebase/dto';
import { useUsersStore } from '@/stores/users';
import { messageType } from '@/common/appCode';

export const useMessagesStore = defineStore('messages', () => {
  const userStore = useUsersStore();

  // stateはref変数
  const messages = ref({}) as Ref<{ [key: string]: Message[] }>;
  const messageTimeLine = ref([]) as Ref<MessageTimeLine[]>;
  const messageLogs = ref({ sendMessage: {}, receiveMessage: {} }) as Ref<GetMessageDataRes>;
  const dbUnsubscriber = ref() as Ref<Unsubscribe | undefined>;

  // gettterはcomputed
  const useComputables = () => {
    return {
      getMessages: computed(() => messages.value),
      getMessageTimeLines: computed(() => messageTimeLine.value),
      getMessageLogs: computed(() => messageLogs.value),
      getMessageArray: computed(() =>
        Object.keys(messages.value)
          .reduce((res: Message[], key): Message[] => [...res, ...messages.value[key]], [])
          .sort((m1, m2) => {
            if (m1.sendTimeDate > m2.sendTimeDate) return -1;
            if (m1.sendTimeDate < m2.sendTimeDate) return 1;
            return 0;
          })
      ),
    };
  };
  const computables = useComputables();

  // actionはmutationと統合して、関数
  /**
   * rMessageの監視を開始
   *
   * @param commit
   * @param rootState
   * @param obj
   */
  const startMessagesSubscribe = (obj: { contractId: string }) => {
    // メッセージの情報をリッスン
    const unsubscriber = onSnapshot(docs.messageDoc(obj.contractId, userStore.getStoreId), (doc) => {
      if (!doc.exists()) {
        messageTimeLine.value = [] as MessageTimeLine[];
      } else {
        const now = new Date();

        api
          .lineMessage<GetMessageDataReq, GetMessageDataRes>({
            process: 'get_message',
            contractId: obj.contractId,
            storeId: userStore.getStoreId,
            fromStr: Utils.dateTimeFormatBySlash(Utils.addDays(now, -1)),
            toStr: Utils.dateTimeFormatBySlash(Utils.addDays(now, 1)),
          })
          .then((res) => {
            const send: SendMessageItem[] = Object.keys(res.sendMessage).flatMap((key) => res.sendMessage[key]);
            const receive: ReceiveMessageItem[] = Object.keys(res.receiveMessage).flatMap(
              (key) => res.receiveMessage[key]
            );

            const messages = [
              ...send.map(
                (val): MessageTimeLine => ({
                  type: 'send',
                  tableId: '',
                  lineUserId: '',
                  lineUserName: '',
                  tableNo: val.tableNo?.reduce((res, val) => `${res}${val} `, '') ?? '',
                  message: val.message,
                  sendTime: Utils.parseDate(val.sendTime),
                })
              ),
              ...receive.map(
                (val): MessageTimeLine => ({
                  type: val.messageType === messageType.notification ? 'notification' : 'receive',
                  tableId: val.tableId,
                  tableNo: val.tableNo,
                  lineUserId: val.lineUserId,
                  lineUserName: val.lineUserName,
                  message: val.message,
                  sendTime: Utils.parseDate(val.sendtime),
                })
              ),
            ];
            messageTimeLine.value = messages;
          });
      }
    });
    dbUnsubscriber.value = unsubscriber;
  };

  /**
   * rMessageの監視を終了
   *
   * @param state
   */
  const stopMessagesSubscribe = () => {
    if (dbUnsubscriber.value) {
      dbUnsubscriber.value();
    }
  };
  /**
   * メッセージの送信
   *
   * @param rootState
   * @param title
   * @param obj
   */
  const sendLineMessage = async (obj: {
    contractId: string;
    message: LineMessageContent[];
    lineUsers: LineUser[];
    tableNo: string[];
  }) => {
    await api
      .lineMessage<SendLineMessageReq, SendLineMessageRes>({
        process: 'send_message',
        contractId: obj.contractId,
        storeId: userStore.getStoreId,
        message: obj.message,
        lineUsers: obj.lineUsers,
        tableNo: obj.tableNo,
      })
      .catch((error) => {
        throw error;
      });

    for (const msg of obj.message) {
      messageTimeLine.value = [
        ...messageTimeLine.value,
        {
          type: 'send',
          tableId: '',
          tableNo: obj.tableNo.reduce((res, val) => `${res}${val} , `),
          lineUserId: '',
          lineUserName: '',
          message:
            msg.type === 'text'
              ? msg.message ?? ''
              : msg.type === 'questionnaire'
                ? 'アンケート送信'
                : `${msg.originalUrl ?? ''}\n${msg.thumbnailUrl ?? ''}`,
          sendTime: new Date(),
        },
      ];
    }
  };

  /**
   * メッセージログの取得
   *
   * @param state
   * @param commit
   * @param rootState
   * @param obj
   */
  const fetchMessageLogs = async (obj: { contractId: string; date: Date }) => {
    try {
      // 指定日の月初
      const beginningDate = new Date(obj.date.getFullYear(), obj.date.getMonth(), 1);

      const from = Utils.dateFormatBySlash(beginningDate);
      const to = Utils.dateFormatBySlash(Utils.addMonths(beginningDate, 1));

      const res = await api.lineMessage<GetMessageDataReq, GetMessageDataRes>({
        process: 'get_message',
        contractId: obj.contractId,
        storeId: userStore.getStoreId,
        fromStr: from,
        toStr: to,
      });

      messageLogs.value = res;
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  return { ...computables, startMessagesSubscribe, stopMessagesSubscribe, sendLineMessage, fetchMessageLogs };
});
