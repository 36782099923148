import { Options, Vue } from 'vue-class-component';
import * as Util from '../utils';
import { useUsersStore } from '@/stores/users';
import { Settings } from '@/store/models/settings';
import { Permission, storeType } from '../appCode';
import { AccountSetting } from '@/store/models/account-setting';
import { CommonSettings, CustmizedContractId, CustmizedContractIds } from '../common-settings';
import { useDisplay } from 'vuetify';

@Options({})
export class Formattable extends Vue {
  screen = useDisplay();
  userStore = useUsersStore();

  get settings(): Settings {
    return this.userStore.getSettings;
  }

  get accountSetting(): AccountSetting {
    return this.userStore.getAccountSetting;
  }

  get is2ndGenData(): boolean {
    return this.accountSetting.is2ndGenData;
  }

  get contractId(): string {
    return this.userStore.getUser?.uid ?? this.accountSetting?.params?.contractId ?? '';
  }

  get storeId(): string {
    return this.userStore.getStoreId ?? '';
  }

  get storeName(): string {
    return this.userStore.getCurrentStore?.name ?? '';
  }

  get staffId(): string {
    return this.userStore.getStaff;
  }

  get isEatinStore(): boolean {
    return this.accountSetting.isEatinStore;
  }

  get isTakeoutStore(): boolean {
    return this.accountSetting.isTakeoutStore;
  }

  get isBothStore(): boolean {
    return this.accountSetting.isBothStore;
  }

  get isWaitingStore(): boolean {
    return this.accountSetting.isWaitingStore;
  }

  get isEcStore(): boolean {
    return this.accountSetting.isEcStore;
  }

  get isMembershipStore(): boolean {
    return this.accountSetting.isMembershipStore;
  }

  get isUseStampCard(): boolean {
    return this.accountSetting.params.membershipInfo?.useStampCard ?? false;
  }

  get isEatinPlus(): boolean {
    return this.accountSetting.isEatinPlus;
  }

  get environment(): string {
    return process.env.VUE_APP_MODE ?? '';
  }

  get isProduction(): boolean {
    return this.environment === 'production';
  }

  get isStaging(): boolean {
    return this.environment === 'staging';
  }

  get isLocal(): boolean {
    return this.environment === 'local';
  }
  get isAdminUser(): boolean {
    return this.userStore.getPermission === Permission.admin;
  }

  get isNormalUser(): boolean {
    return this.userStore.getPermission === Permission.normal;
  }

  get isMemberUser(): boolean {
    return this.userStore.getPermission === Permission.member;
  }

  get isHeadquarter(): boolean {
    // 空の場合は旧データなので本部と判定
    return this.userStore.getStoreType === storeType.headquarters || this.userStore.getStoreType === '';
  }

  get isNormalStore(): boolean {
    // 空の場合は旧データなので店舗と判定
    return this.userStore.getStoreType === storeType.store || this.userStore.getStoreType === '';
  }

  get isHeadquarterLogin(): boolean {
    return Boolean(sessionStorage.getItem(CommonSettings.WEB_STORAGE_KEY.HEADQUARTER_STORE_ID));
  }

  get headquarterPassword(): string {
    return sessionStorage.getItem(CommonSettings.WEB_STORAGE_KEY.HEADQUARTER_PASSWORD) ?? '';
  }

  get isHeadquarterOf2ndGen(): boolean {
    // 新データで本部なのかを判定
    return this.is2ndGenData && this.isHeadquarter;
  }

  numberWithComma(num: number): string {
    return num?.toLocaleString() ?? '0';
  }
  numberWithCommaAndNoDecimal(num: number): string {
    return num.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 });
  }
  timeByColon(date: Date | null | undefined): string {
    return date ? Util.timeFormatByColon(date) : '--:--';
  }
  dateTimeBySlash(date: Date | null): string {
    return date ? Util.dateTimeFormatBySlash(date) : '----/--/-- --:--';
  }
  dateTimeByHyphen(date: Date | null): string {
    return date ? Util.dateTimeFormatByHyphen(date) : '';
  }

  // 資さんのように、指定された契約かを判定する
  isSpecifiedContract(contractName: keyof CustmizedContractId): boolean {
    return CustmizedContractIds[contractName] === this.contractId;
  }
}
