import { defineStore } from 'pinia';
import { Ref, computed, ref } from 'vue';
import { StoreError } from '@/common/error';
import { Store } from '@/store/models/store';
import { api } from '@/firebase/api';
import { AddStoreParam, DeleteStoreParam, GetStoreParam, GetStoreRes, UpdateStoreParam } from '@/firebase/dto/store';
import { RStore } from '@/store/models/db/r-store';
import {
  GetSmaregiStoreReq,
  GetSmaregiStoreRes,
  ImportSmaregiStoreReq,
  ImportSmaregiStoreRes,
} from '@/firebase/dto/smaregi';
import { SmaregiStore } from '@/store/models/db/r-settings';
import { useUsersStore } from '@/stores/users';

export const useStoreStore = defineStore('store', () => {
  const userStore = useUsersStore();

  // stateはref変数
  const stores = ref([]) as Ref<Store[]>;

  // gettterはcomputed
  const storeList = computed(() => stores.value);

  // actionはmutationと統合して、関数
  /**
   * rStoreを取得する
   *
   * @param obj
   */
  const fetchStores = async (obj: { contractId: string }): Promise<void> => {
    try {
      const res = await api.store<GetStoreParam, GetStoreRes>({
        process: 'get',
        contractId: obj.contractId,
      });
      stores.value = res.stores.map((store) => new Store(store));
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  /**
   * rStoreを取得する
   *
   * @param obj
   */
  const getStore = async (obj: { contractId: string; storeId: string }): Promise<Store | null> => {
    try {
      const res = await api.store<GetStoreParam, GetStoreRes>({
        process: 'get',
        contractId: obj.contractId,
        storeId: obj.storeId,
      });

      return res.stores[0] ? new Store(res.stores[0]) : null;
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  /**
   * 店舗を追加する
   *
   * @param obj
   */
  const addStore = async (obj: { contractId: string; store: RStore }): Promise<void> => {
    try {
      await api.store<AddStoreParam, void>({
        process: 'add',
        contractId: obj.contractId,
        store: obj.store,
      });
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  /**
   * 店舗を更新する
   *
   * @param obj
   */
  const updateStore = async (obj: { contractId: string; store: Partial<RStore> }): Promise<void> => {
    try {
      await api.store<UpdateStoreParam, void>({
        process: 'update',
        contractId: obj.contractId,
        store: obj.store,
      });
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  /**
   * 店舗を削除する
   *
   * @param obj
   */
  const deleteStore = async (obj: { contractId: string; storeId: string }): Promise<void> => {
    try {
      await api.store<DeleteStoreParam, void>({
        process: 'delete',
        contractId: obj.contractId,
        storeId: obj.storeId,
      });
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  /**
   * スマレジから店舗を取得する
   *
   * @param obj
   */
  const getSmaregiStore = async (obj: { contractId: string }): Promise<SmaregiStore[]> => {
    try {
      const res = await api.smaregi<GetSmaregiStoreReq, GetSmaregiStoreRes>({
        process: 'get_smaregi_store',
        contractId: obj.contractId,
        storeId: userStore.getStoreId,
      });

      return res.stores;
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  /**
   * スマレジから店舗をインポートする
   *
   * @param obj
   */
  const importSmaregiStore = async (obj: { contractId: string; storeIds: string[] }): Promise<void> => {
    try {
      await api.smaregi<ImportSmaregiStoreReq, ImportSmaregiStoreRes>({
        process: 'import_smaregi_store',
        contractId: obj.contractId,
        storeId: userStore.getStoreId,
        smaregiStoreIds: obj.storeIds,
      });
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  return {
    stores,
    storeList,
    fetchStores,
    getStore,
    addStore,
    updateStore,
    deleteStore,
    getSmaregiStore,
    importSmaregiStore,
  };
});
