import { Multilingual } from '@/store/models/db/common-models';

export interface MessageResource {
  FINISH_ORDER: Multilingual;
  HISTORY_ACCOUNT: Multilingual;
  PAY: Multilingual;
  ORDER_HISTORY: Multilingual;
  RE_ORDER: Multilingual;
  REPEAT_LAST_ORDER: Multilingual;
  TAX_INCLUDED: Multilingual;
  REMAINING: Multilingual;
  SOLD_OUT: Multilingual;
  ORDER: Multilingual;
  ADD_TO_CART: Multilingual;
  CHANGE: Multilingual;
  VIEW_CART: Multilingual;
  CART: Multilingual;
  MY_ORDER: Multilingual;
  SEND_TO_ORDER: Multilingual;
  CLOSE: Multilingual;
  ORDER_CONFIRM: Multilingual;
  SCAN_QRCODE_TO_ORDER: Multilingual;
  RECEIVED_ORDER: Multilingual;
  CONTINUE_TO_ORDER: Multilingual;
  THANKS_FOR_ORDER: Multilingual;
  ABOUT_ORDER_HISTORY: Multilingual;
  OTHER_REQUESTS: Multilingual;
  SELECT_NUMBER: Multilingual;
  PREORDER_UNAVAILABLE: Multilingual;
  TICKETED_NUMBERS: Multilingual;
  CALLING: Multilingual;
  ALREADY_CALLED: Multilingual;
  CURRENT_WAITING: Multilingual;
  GROUP: Multilingual;
  PEOPLE: Multilingual;
  IMPORTANT_NOTES: Multilingual;
  NOW: Multilingual;
  NUMBER_OF_WAITINGS: Multilingual;
  NUMBER_OF_CUSTOMERS: Multilingual;
  INPUT_NUMBER_OF_CUSTOMERS: Multilingual;
  SELECT_NUMBER_OF_CUSTOMERS: Multilingual;
  ADULT: Multilingual;
  CHILD: Multilingual;
  REGISTER: Multilingual;
  ISSUE_TICKET: Multilingual;
  NEXT: Multilingual;
  CANCEL: Multilingual;
  CONFIRM: Multilingual;
  TICKETING: Multilingual;
}

export type MessageResourceKeys = keyof MessageResource;

export const messageResource: MessageResource = {
  FINISH_ORDER: {
    ja: 'ご注文は終了しました',
    en: 'Your order is closed',
    zh: '您的订单已关闭',
    ko: '주문이 종료되었습니다.',
  },
  HISTORY_ACCOUNT: {
    ja: '注文履歴',
    en: 'History',
    zh: '历史/会计',
    ko: '이력·회계',
  },
  PAY: {
    ja: 'お会計',
    en: 'Pay',
    zh: '会计',
    ko: '회계',
  },
  ORDER_HISTORY: {
    ja: '注文履歴',
    en: 'History',
    zh: '订单历史',
    ko: '주문 내역',
  },
  RE_ORDER: {
    ja: '履歴から注文',
    en: 'Re-order',
    zh: '重新下单',
    ko: '이전 주문 재주문',
  },
  REPEAT_LAST_ORDER: {
    ja: '前回来店時の注文',
    en: 'Repeat last order',
    zh: '重复上次访问的订单',
    ko: '지난 방문 때의 주문 반복',
  },
  TAX_INCLUDED: {
    ja: '税込',
    en: 'Tax incl',
    zh: '含税',
    ko: '세금 포함',
  },
  REMAINING: {
    ja: '残',
    en: 'Rem',
    zh: '其余的',
    ko: '남은',
  },
  SOLD_OUT: {
    ja: '完売しました',
    en: 'Sold out',
    zh: '售罄',
    ko: '매진',
  },
  ORDER: {
    ja: '注文',
    en: 'Order',
    zh: '命令',
    ko: '주문',
  },
  ADD_TO_CART: {
    ja: 'カートに追加',
    en: 'Add to cart',
    zh: '添加到购物车',
    ko: '장바구니에 담기',
  },
  CHANGE: {
    ja: '変更',
    en: 'Change',
    zh: '改变',
    ko: '변경',
  },
  VIEW_CART: {
    ja: 'カートを見る',
    en: 'View cart',
    zh: '查看购物车',
    ko: '장바구니보기',
  },
  CART: {
    ja: 'カート',
    en: 'Cart',
    zh: '大车',
    ko: '카트',
  },
  MY_ORDER: {
    ja: 'ご注文内容の確認',
    en: 'My Order',
    zh: '确认您的订单',
    ko: '주문 내용 확인',
  },
  SEND_TO_ORDER: {
    ja: '注文を送信',
    en: 'Send to order',
    zh: '发送到订单',
    ko: '주문 보내기',
  },
  CLOSE: {
    ja: '閉じる',
    en: 'Close',
    zh: '特写',
    ko: '닫기',
  },
  ORDER_CONFIRM: {
    ja: '※「注文」を押すと注文が確定します',
    en: '※Tap [Order] to confirm the order',
    zh: '※按[订单]确认订单',
    ko: '※[주문]을 누르면 주문이 확정됩니다.',
  },
  SCAN_QRCODE_TO_ORDER: {
    ja: '※ご注文はQRコードを読み込んでください',
    en: '※Please scan the QR code at the store to order',
    zh: '※请扫描店内二维码下单',
    ko: '※매장에서 QR코드를 스캔하여 주문하세요',
  },
  RECEIVED_ORDER: {
    ja: '注文を承りました',
    en: 'Received your order',
    zh: '我收到了订单',
    ko: '주문을 받았습니다.',
  },
  CONTINUE_TO_ORDER: {
    ja: '注文を続ける',
    en: 'Continue Ordering',
    zh: '继续点单',
    ko: '주문 계속하기.',
  },
  THANKS_FOR_ORDER: {
    ja: 'ご注文ありがとうございます',
    en: 'Thank you for your order',
    zh: '谢谢您的订购',
    ko: '주문해 주셔서 감사합니다.',
  },
  ABOUT_ORDER_HISTORY: {
    ja: '注文情報は画面下の「注文履歴」からご確認いただけます',
    en: 'You can check your order history at the bottom of the screen',
    zh: '您可以在屏幕底部查看您的订单历史',
    ko: '주문 내역은 화면 하단의 "주문 내역"에서 확인할 수 있습니다.',
  },
  OTHER_REQUESTS: {
    ja: 'ご要望など',
    en: 'Any other Requests?',
    zh: '其他要求',
    ko: '기타 요청',
  },
  SELECT_NUMBER: {
    ja: `個数を選択してください。`,
    en: `Please select the number.`,
    zh: `请选择号码`,
    ko: `개수를 선택해 주세요.`,
  },
  PREORDER_UNAVAILABLE: {
    ja: `こちらの商品は在庫が残り少ないため、事前注文いただけません。申し訳ございません。`,
    en: `I'm sorry but you can't order this item in preorder because of it's small stock.`,
    zh: `很抱歉，由于库存量少，您无法预购此商品。`,
    ko: `죄송합니다만 이 상품은 재고가 적어 예약 주문을 할 수 없습니다.`,
  },
  // 順番待ちの多言語対応
  TICKETED_NUMBERS: {
    ja: `発券済みの番号`,
    en: `Waitlisted ticket numbers`,
    zh: `已出票`,
    ko: `발권된 번호`,
  },
  CALLING: {
    ja: `呼び出し中`,
    en: `Calling`,
    zh: `呼唤`,
    ko: `전화 중`,
  },
  ALREADY_CALLED: {
    ja: `呼び出し済み`,
    en: `Already called`,
    zh: `已呼叫`,
    ko: `호출 완료`,
  },
  CURRENT_WAITING: {
    ja: `ただいまの待ち状況`,
    en: `Current waiting list`,
    zh: `当前等待状况`,
    ko: `실시간 대기 정보`,
  },
  GROUP: {
    ja: `組`,
    en: `groups`,
    zh: `组`,
    ko: `조`,
  },
  PEOPLE: {
    ja: `名`,
    en: `people`,
    zh: `位`,
    ko: `명`,
  },
  IMPORTANT_NOTES: {
    ja: `受付前にお読みください`,
    en: `Important notes before registration.`,
    zh: `请在排队前阅读`,
    ko: `접수 앞에 읽어 주세요`,
  },
  NOW: {
    ja: `ただいまの`,
    en: `Now`,
    zh: `当前`,
    ko: `현재`,
  },
  NUMBER_OF_WAITINGS: {
    ja: `待ち組数`,
    en: `Waitings`,
    zh: `等待组数`,
    ko: `대기 중인 그룹 수`,
  },
  NUMBER_OF_CUSTOMERS: {
    ja: `人数`,
    en: `Number of customers`,
    zh: `人数`,
    ko: `인원수`,
  },
  INPUT_NUMBER_OF_CUSTOMERS: {
    ja: `人数を入力してください`,
    en: `Please enter the number of people in your group.`,
    zh: `请输入人数`,
    ko: `인원수를 입력하세요.`,
  },
  SELECT_NUMBER_OF_CUSTOMERS: {
    ja: `人数を選んでください`,
    en: `Please select the number of people in your group.`,
    zh: `请选择人数`,
    ko: `인원수를 선택해 주세요`,
  },
  ADULT: {
    ja: `大人`,
    en: `Adults`,
    zh: `成人`,
    ko: `어른`,
  },
  CHILD: {
    ja: `子ども`,
    en: `Children`,
    zh: `儿童`,
    ko: `어린이`,
  },
  REGISTER: {
    ja: `受付する`,
    en: `Register`,
    zh: `签到`,
    ko: `접수하다`,
  },
  ISSUE_TICKET: {
    ja: `発券する`,
    en: `Issue Ticket`,
    zh: `出票`,
    ko: `발권하다`,
  },
  NEXT: {
    ja: `次へ`,
    en: `Next`,
    zh: `到下一个`,
    ko: `다음`,
  },
  CANCEL: {
    ja: `キャンセル`,
    en: `Cancel`,
    zh: `取消`,
    ko: `취소`,
  },
  CONFIRM: {
    ja: `こちらの内容で受け付けます`,
    en: `We will confirm with the details below.`,
    zh: `我们将接受这个内容`,
    ko: `이 내용으로 접수합니다`,
  },
  TICKETING: {
    ja: `発券`,
    en: `Ticketing`,
    zh: `售票处`,
    ko: `발권`,
  },
};

export interface MessageFunction {
  SELECTED_ITEM: (str: string) => Multilingual;
  SELECT_REQUEST1_CHOICES_MIN: (min: number) => Multilingual;
  SELECT_REQUEST1_CHOICES_MIN_MAX: (min: number, max: number) => Multilingual;
}

export const messageFunction: MessageFunction = {
  SELECTED_ITEM: (str: string) => ({
    ja: `${str}を選択してください。`,
    en: `Please select a ${str}.`,
    zh: `请选择一个${str}。`,
    ko: `${str}을 선택합니다.`,
  }),
  SELECT_REQUEST1_CHOICES_MIN: (min: number) => ({
    ja: `${min}名以上で選択いただけます`,
    en: `Can be selected by ${min} or more people.`,
    zh: `可由${min}人或以上选择`,
    ko: `${min}명 이상 선택 가능`,
  }),
  SELECT_REQUEST1_CHOICES_MIN_MAX: (min: number, max: number) => ({
    ja: `${min}名から${max}名で選択いただけます`,
    en: `You can choose from ${min} to ${max} people.`,
    zh: `您可以选择 ${min} 至 ${max} 人。`,
    ko: `${min}명에서 ${max}명으로 선택할 수 있습니다.`,
  }),
};
